import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import { ConditionsType, RawTriggerData } from "@/components/TriggerSetup/logic/types/types";

@Component
export default class TriggersListMixin extends Vue {
  triggersHighlightTo = {}

  searchQuery = ''

  @Watch('searchQuery')
  onSearchQueryChange(value: string) {
    if (!value.trim()) {
      this.triggersHighlightTo = {}
    }
  }

  queriedTriggers(triggers: Array<RawTriggerData>, sortFn: (a:RawTriggerData, b:RawTriggerData) => number): Array<RawTriggerData> {
    const nameFilterTriggers = (triggers ?? [])
        .filter((t) => t.name.toLowerCase().includes(this.searchQuery.trim().toLowerCase()))

    if (!nameFilterTriggers.length) {
      return (triggers ?? [])
          .filter((t) => this.findTriggerConditions(t, this.searchQuery))
          .sort(sortFn)
    }

    this.triggersHighlightTo = {}

    return nameFilterTriggers.sort(sortFn)
  }

  findTriggerConditions(trigger:any, query: string) {
    const conditionWithChipValues = [
      ConditionsType.MessageTextFullMatch,
      ConditionsType.MessageTextEndWith,
      ConditionsType.MessageTextStartWith,
      ConditionsType.MessageTextSubstringMatch,
      ConditionsType.MessageWordStartWith,
      ConditionsType.MessageWordEndWith,
      ConditionsType.MessageWordFullMatch
    ]

    const lowerCaseAndTrim = (value: string) => value.trim().toLowerCase();

    const conditions = trigger.conditions.find((group: any) => group.some((c: any) => conditionWithChipValues.includes(c.type) && c?.value.map(lowerCaseAndTrim).some((v:any) => v.includes(lowerCaseAndTrim(query)))))

    if (conditions !== undefined) {
      this.$set(this.triggersHighlightTo, trigger.id, conditions.map((c: any) => c.value.filter((v: string) => v.includes(lowerCaseAndTrim(query)))))

      return true
    }

    this.$delete(this.triggersHighlightTo, trigger.id)

    return false
  }
}
