


























import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
})
export default class TriggersListSectionTitle extends Vue {
  @Prop() title!:string

  @Prop({ type: Boolean }) showAddButton!:boolean

  @Emit('addButtonClick')
  addNewButtonClick(): void {}
}
