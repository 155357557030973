var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.networkConfig)?_c('div',{staticClass:"network-triggers"},[_c('page-title',[_c('template',{slot:"custom-button"},[_c('a-button',{attrs:{"icon":"arrow-left","type":"link"},on:{"click":function($event){return _vm.gotoNetDashboard(_vm.networkConfig.id)}}},[_vm._v(" "+_vm._s(_vm.$t("menu_networkdashboard_title"))+" ")]),(!_vm.readonlyNetwork)?_c('a-button',{staticClass:"btn-success",attrs:{"icon":"save"},on:{"click":_vm.saveNetworkConfigVm}},[_vm._v(" "+_vm._s(_vm.$t('save_settings'))+" ")]):_vm._e()],1)],2),(_vm.groupsWithoutTriggers)?_c('a-alert',{staticClass:"mb-5",attrs:{"show-icon":"","type":"error"}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('groups_without_triggers_alert_message', [ _vm.groupsWithoutTriggers ]))},slot:"message"})]):_vm._e(),(!!_vm.triggers)?[_c('div',{staticClass:"w-full sm:w-1/2 ml-auto"},[_c('search-text-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': this,
            'key': 'searchQuery',
            'titleVisible': false,
            'placeholder': _vm.$t('triggers_search_input_placeholder')
          }
        }}})],1),(!_vm.searchQuery)?[(_vm.sortedActiveTriggers.length || !_vm.inactiveTriggers.length)?[_c('triggers-list-section-title',{attrs:{"title":_vm.$t('trigger_active_trigger_list'),"show-add-button":!_vm.readonlyNetwork},on:{"addButtonClick":function($event){return _vm.gotoNetworkTrigger('new')}}}),_c('trigger-card-list-wrapper',{attrs:{"triggers":_vm.sortedActiveTriggers,"triggers-highlight-to":{},"readonly":_vm.readonlyNetwork,"user-triggers":_vm.networkConfig.triggers},on:{"editTrigger":_vm.editTrigger,"removeFromActive":_vm.removeFromActive,"addToActive":_vm.addToActive,"copyTrigger":_vm.copyTrigger,"removeTrigger":_vm.removeTrigger,"moveTriggerDirection":_vm.moveToDirection,"moveTriggerIndex":_vm.moveToIndex}})]:_vm._e(),(_vm.inactiveTriggers.length)?[_c('triggers-list-section-title',{attrs:{"title":_vm.$t('trigger_non_active_trigger_list'),"show-add-button":!_vm.sortedActiveTriggers.length}}),_c('trigger-card-list-wrapper',{attrs:{"triggers":_vm.inactiveTriggers,"triggers-highlight-to":{},"user-triggers":_vm.networkConfig.triggers},on:{"editTrigger":_vm.editTrigger,"removeTrigger":_vm.removeTrigger,"copyTrigger":_vm.copyTrigger,"removeFromActive":_vm.removeFromActive,"addToActive":_vm.addToActive,"moveTriggerDirection":_vm.moveToDirection,"moveTriggerIndex":_vm.moveToIndex}})]:_vm._e()]:[_c('triggers-list-section-title',{attrs:{"title":_vm.$t('trigger_trigger_list'),"show-add-button":""},on:{"addButtonClick":function($event){return _vm.gotoNetworkTrigger('new')}}}),_c('trigger-card-list-wrapper',{attrs:{"triggers":_vm.searchTriggers,"triggers-highlight-to":_vm.triggersHighlightTo,"user-triggers":_vm.networkConfig.triggers},on:{"editTrigger":_vm.editTrigger,"copyTrigger":_vm.copyTrigger,"removeTrigger":_vm.removeTrigger,"removeFromActive":_vm.removeFromActive,"addToActive":_vm.addToActive,"moveTriggerDirection":_vm.moveToDirection,"moveTriggerIndex":_vm.moveToIndex}})]]:_c('a-spin',{staticClass:"w-full"})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }